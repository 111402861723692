<template>
    <v-dialog v-model="dialog" width=900>
        <v-card>
            <v-form ref="form" v-model="formValid">
                <v-card-title
                    class="headline grey lighten-2"
                    primary-title
                >
                    <div v-if="!tipo">Medicamentos y material de curación</div>
                    <div v-if="tipo==1">Listado de Medicamentos</div>
                    <div v-if="tipo==2">Listado de material de curación</div>
                </v-card-title>

                <v-card-text>

                    <v-text-field
                        class="mx-5 mb5"

                        v-model="query"
                        clearable
                        label="Buscar"
                    />

                    <v-data-table
                        elevation="2"
                        :headers="headers"
                        disable-sort
                        :footer-props="{
                            'items-per-page-options': [10, 20, 30, 40, 50]
                        }"

                        item-key="id"
                        :loading="loadingMeds"

                        :server-items-length='total'
                        :options.sync="optionsTable"

                        class="elevation-1"

                        :items="meds"
                        disable-pagination
                        disable-filtering

                    >
                        <template v-slot:[`item.especificacion`]="{ item }">
                            <span v-html='item.especificacion'/>
                        </template>
                        <template v-slot:[`item.observaciones`]="{ item }">
                            <span v-html='item.observaciones'/>
                        </template>
                        <template v-slot:[`item.actions`]="{ item }">
                            <v-btn
                                icon
                                @click="seleccionaMed(item)"
                            >
                                <v-icon color="green">mdi-check-bold</v-icon>
                            </v-btn>
                        </template>

                    </v-data-table>

                </v-card-text>

                <v-card-actions>
                    <v-spacer/>

                </v-card-actions>
            </v-form>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    props:{
        dialogModal: Boolean,
        medicamento: Number,
        tipo: Number,
    },

    data:()=>({
        formValid:false,
        loadingBtn:false,
        query:'',
        params:{},
        optionsTable:{},
        total:0,

        headers:[
            {text:'Clave', value:'clave', width:'20%',},
            {text:'Concepto', value:'concepto', width:'30%'},
            {text:'Especificacion', value:'especificacion'},
            {text:'', value:'actions', width:'10%'},

        ],
        meds:[],
        loadingMeds:false,
        //esteMed:{},

    }),

    methods:{
        cancela(){
            this.dialog=false
        },
        async guarda(){
            this.loadingBtn=true
            this.loadingBtn=false
            this.dialog=false
        },
        async list(){
            this.loadingMeds=true
                if(this.tipo){
                    this.params.tipoInsumo=this.tipo
                }
                if(this.query){
                    this.params.query=this.query
                }

                this.params.start=(this.optionsTable.page-1 )*this.optionsTable.itemsPerPage
                this.params.limit=this.optionsTable.itemsPerPage
            try{
                let eva = await this.$http({
                    url: '/comi/listMedicamentoCb',
                    method:'GET',
                    params: this.params,
                })
                this.loadingMeds=false
                this.meds = eva.data.root
                this.total = eva.data.total
            }catch(err){
                this.loadingMeds=false
                this.$store.dispatch('security/revisaError',err)
            }
        },
        seleccionaMed(v){
            this.esteMed=v.id
            this.dialog=false
        },
    },

    computed:{
        dialog:{
            get(){return this.dialogModal},
            set(val){this.$emit('update:dialogModal',val)}
        },
        esteMed:{
            get(){return this.esteMed},
            set(val){this.$emit('update:medicamento',val)}
        }
    },
    watch:{

        query(v){

            if ((v && v.length >=3 && !this.loadingMeds) || !v){
                    this.optionsTable.page = 1,
                    this.list()
            }
        },

        dialog(v){
            if (v){
                this.params.query=""
            }

        },

        optionsTable: {
            handler () {
                //console.log(this.optionsTable)
                this.list()
            },
            deep: true,
        },

    },
}
</script>

<style>

</style>